import React from 'react';

import stylisRtl from "stylis-rtl";
import { CacheProvider } from "@emotion/core";
import createCache from "@emotion/cache";

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout/index';

import '../../../../fonts/Iranyekan/css/fontiran.css';

const cache = createCache({ stylisPlugins: [stylisRtl] });

export default function({ children }) {
  return (
    <CacheProvider value={cache}>
      <Layout>{children}</Layout>
    </CacheProvider>
  );
}